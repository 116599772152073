import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VueCookieNext } from 'vue-cookie-next'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

// axios.defaults.baseURL = 'http://nashoof-sd.com/BYTE_AREA/NashoofAPI/APIs/'
createApp(App)
     .use(router)
     .use(VueAxios, axios)
     .use(VueCookieNext)
     .use(Toast)
     .use(VueLoading, {
          // props
          color: '#236EC5',
          loader:'dots'
      })
     .mount('#app')

import { createRouter, createWebHistory } from 'vue-router'
function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: lazyLoad('Home')
  },
  {
    path: '/hE_Check',
    name: 'HE_Check',
    component: () => import(/* webpackChunkName: "HE_Check" */ '../components/Pages/HE_Check.vue')
  },
  {
    path: '/Landing',
    name: 'Landing',
    component: () => import(/* webpackChunkName: "Landing" */ '../components/Pages/landing.vue')
  },
  {
    path: '/search/:search_id',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '../views/page/Search.vue'),
    props: true
  },
  {
    path: '/content/:Content_id',
    name: 'Content',
    component: () => import(/* webpackChunkName: "content" */ '../views/page/Content.vue'),
    props: true
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import(/* webpackChunkName: "subscribe" */ '../views/user/Subscribe.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/user/Signup.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue')
  },
  {
    path: '/unsubscribes',
    name: 'Unsubscribes',
    component: () => import(/* webpackChunkName: "unsubscribes" */ '../views/user/Unsubscribes.vue')
  },
  {
    path: '/art',
    name: 'Art',
    component: () => import(/* webpackChunkName: "art" */ '../views/category/Art.vue')
  },
  {
    path: '/comedy',
    name: 'Comedy',
    component: () => import(/* webpackChunkName: "comedy" */ '../views/category/Comedy.vue')
  },
  {
    path: '/artist',
    name: 'Artist',
    component: () => import(/* webpackChunkName: "art" */ '../views/category/Artist.vue')
  },
  {
    path: '/artistContent',
    name: 'ArtistContent',
    component: () => import(/* webpackChunkName: "art" */ '../views/category/ArtistContent.vue')
  }
  ,
  {
    path: '/seriesContent',
    name: 'SeriesContent',
    component: () => import(/* webpackChunkName: "art" */ '../views/category/SeriesContent.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
